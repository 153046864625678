<template>
  <v-container class="mt-3">
    <v-row>
      <v-col cols="12" md="8">
        <v-card height="300">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-row class="ma-0">
                <v-col md="12">
                  <v-row class="d-flex flex-column mb-2 mx-0 mt-1">
                    <div class="d-flex justify-space-between align-center">
                      <div
                        class="font-weight-medium text-caption text-uppercase"
                      >
                        {{ $t("add_users") }}
                      </div>
                    </div>
                    <v-divider class="SimpleSolid"></v-divider>
                  </v-row>
                  <v-row class="mx-0" v-show="false">
                    <label
                      :for="$t('employee')"
                      class="mr-1 mt-1 black--text text-body-2"
                      >{{ $t("employee") }}</label
                    >
                    <input
                      v-model="type"
                      type="radio"
                      name="moderator"
                      :value="'INTERNAL'"
                      :id="$t('employee')"
                      class="mr-8"
                    />
                    <label
                      :for="$t('trainees')"
                      class="mr-1 mt-1 black--text text-body-2"
                      >{{ $t("trainees") }}</label
                    >
                    <input
                      v-model="type"
                      type="radio"
                      name="moderator"
                      :value="'EXTERNAL'"
                      :id="$t('trainees')"
                    />
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
                <v-col md="6">
                  <div>
                    <v-autocomplete
                      v-if="User.id"
                      class="caption black--text"
                      v-model="User.employeeId"
                      :items="filterEmployeesUsers"
                      :itemValue="`id`"
                      :itemText="`fullName`"
                      :placeholder="$t('select_employee')"
                      data-vv-name="select"
                      outlined
                      :rules="[rules.required]"
                      dense
                      @change="selectedEmployee"
                    ></v-autocomplete>
                    <!-- <MultiSelectAutocomplete
                      v-if="User.id"
                      v-model="User.employeeId"
                      :placeholder="$t('select_employee')"
                      :return-object="false"
                      :rules="[rules.required]"
                      :required="true"
                    /> -->
                    <MultiSelectAutocomplete
                      v-else
                      v-model="employeesIds"
                      :placeholder="$t('select_employee')"
                      :return-object="false"
                      :rules="[rules.required]"
                      :required="true"
                    />
                    <!-- <v-autocomplete
                      v-else
                      v-model="employeesIds"
                      :items="filterEmployees"
                      :itemValue="`id`"
                      :itemText="`fullName`"
                      :placeholder="$t('select_employee')"
                      data-vv-name="select"
                      required
                      :rules="[rules.required]"
                      outlined
                      dense
                      multiple
                      clearable
                      @change="selectDeselectAllItems"
                    ></v-autocomplete> -->
                  </div>
                </v-col>
                <v-col md="6">
                  <div>
                    <v-autocomplete
                      class="caption black--text"
                      v-model="User.roleId"
                      :items="roles"
                      :itemValue="`id`"
                      :itemText="`name`"
                      :placeholder="$t('select_role')"
                      data-vv-name="select"
                      outlined
                      dense
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="mx-2">
                <v-col md="6"> </v-col>
                <v-col md="6">
                  <div class="text-right" style="margin-top: -4%">
                    <v-btn
                      v-if="User.id"
                      color="primary"
                      class="mr-2"
                      outlined
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="cancel()"
                    >
                      <span class="text-caption">{{ $t("cancel") }}</span>
                    </v-btn>
                    <v-btn
                      v-if="User.id && accessLevel(currentUser, 'update_user')"
                      color="primary"
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="updateUserById"
                      class="mr-2"
                    >
                      <span class="text-caption"> {{ $t("update") }}</span>
                    </v-btn>
                    <v-menu offset-y left v-if="User.id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          style="background-color: #ccebe9; min-width: 15px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <template v-if="accessLevel(currentUser, 'delete_user')">
                          <v-list-item
                            style="min-height: 25px"
                            @click="deleteUserById"
                          >
                            <v-list-item-title style="padding-top: 0">
                              <span class="black--text text-caption">
                                {{ $t("delete") }}
                              </span>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                    <v-btn
                      v-else-if="accessLevel(currentUser, 'add_user')"
                      color="primary"
                      :loading="isLoading"
                      :disabled="!valid"
                      @click="validate"
                    >
                      <span class="text-caption"> {{ $t("save") }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="whirelab-positions-list">
        <!-- <pre>
          {{allUsers}}
        </pre> -->
        <template v-for="user in allUsers">
          <expansion-card-user
            class="mb-2"
            :user="user"
            :key="user.id"
            @clicked="selectedUser"
          />
        </template>
      </v-col>
    </v-row>
    <WhirelabSnackbar
      :snackbar="snackbar"
      :timeout="timeout"
      :text="snackbar_test"
      :close="closeSnackbar"
    />
  </v-container>
</template>
<script>
import GET_USERS from "../graphql/query/GetUsers.gql";
import GET_ROLES from "../../roles/graphql/query/GetRoles.gql";
import GET_EMPLOYEES from "../../employee/graphql/query/GetEmployees.gql";
import UPDATE_USER_BY_ID from "../graphql/mutation/UpdateUserById.gql";
import CREATE_USER from "../graphql/mutation/CreateUser.gql";
import CREATE_MANY_USERS from "../graphql/mutation/CreateManyUsers.gql";
import DELETE_USER from "../graphql/mutation/DeleteUserById.gql";
import ExpansionCardUser from "../components/ExpansionCardUser.vue";
import WhirelabSnackbar from "@/components/cards/popups/WhirelabSnackbar.vue";
import MultiSelectAutocomplete from "@/components/form/auto-complete/MultiSelectAutocomplete.vue";
// import WhirelabInput from "@/components/form/WhirelabInput.vue";

import accessLevel from "@/mixins/access-level";
import { mapGetters } from "vuex";
export default {
  name: "ListUsers",
  mixins: [accessLevel],
  components: {
    ExpansionCardUser,
    WhirelabSnackbar,
    MultiSelectAutocomplete,
    // WhirelabInput,
  },
  props: {},
  data: () => {
    const code = Math.floor(Math.random() * 90000000) + 10000000;
    return {
      dialog: false,
      users: [],
      show_pass: false,
      confirmpassword: undefined,
      selectedItems: [],
      employeesIds: [],
      User: {
        id: undefined,
        employeeId: null,
        password: code + "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        type: "EMPLOYEE",
        roleId: "",
      },
      filter: {
        sectionId: undefined,
        teamId: undefined,
        gender: undefined,
        search: undefined,
        page: 1,
        pageSize: 10,
      },
      email: "",
      isLoading: false,
      valid: true,
      type: "INTERNAL",
      employees: [],
      timeout: 0,
      snackbar: false,
      snackbar_test: "",
    };
  },

  apollo: {
    users: {
      query: GET_USERS,
      fetchPolicy: "no-cache",
    },
    employees: {
      query: GET_EMPLOYEES,
      variables() {
        return {
          filterEmployeeInput: this.filter,
        };
      },
      result({ data, loading }) {
        console.log("employees", data.employees);
        this.loading = loading;
        if (!loading) {
          this.employees = data.employees;
        }
      },
    },
    roles: GET_ROLES,
  },

  watch: {
    async currentSearch(newVal) {
      this.filter.page = 1;
      this.filter.search = newVal;
      await this.$apollo.queries.employees.refetch();
    },
  },

  computed: {
    ...mapGetters({
      currentSearch: "auth/getCurrentSearch",
      currentUser: "auth/getCurrentUser",
    }),
    rules() {
      return {
        required: (value) => !!value || this.$t("required_field"),
        min: (value) => value.length >= 8 || this.$t("hint_password"),
      };
    },
    filterEmployees() {
      let employees = [];
      employees = this.employees.map((employee) => ({
        ...employee,
        fullName: `${employee.firstName} ${employee.lastName}`,
      }));

      employees = employees.filter((e) => {
        if (e.type && e.type === this.type && !this.isEmployeeHasUser(e.id)) {
          return { ...e, fullName: `${e.firstName} ${e.lastName}` };
        }
      });

      employees.unshift({ id: "Select all", fullName: this.$t("select_all") });

      return employees;
    },

    filterEmployeesUsers() {
      let employees = [];
      employees = this.employees.map((employee) => ({
        ...employee,
        fullName: `${employee.firstName} ${employee.lastName}`,
      }));

      return employees.filter((e) => {
        if (e.type && e.type === this.type && this.isEmployeeHasUser(e.id)) {
          return { ...e, fullName: `${e.firstName} ${e.lastName}` };
        }
      });
    },

    allUsers() {
      return this.users.filter((u) => {
        if (u.employee && u.employee.type === this.type) {
          return u;
        }
      });
    },
  },

  methods: {
    cancel() {
      this.$refs.form.reset();
      this.email = "";
      this.User.id = undefined;
    },

    selectedEmployee(event) {
      const employeeIndex = this.filterEmployees.findIndex(
        (employee) => employee.id === event
      );
      this.email = this.filterEmployees[employeeIndex].email;
    },

    isEmployeeHasUser(employeeId) {
      const userIndex = this.allUsers.findIndex(
        (user) => user.employeeId === employeeId
      );
      return userIndex >= 0;
    },

    selectedUser(UserId) {
      const user = this.users.find((findUser) => findUser.id === UserId);
      this.email = user.email;
      this.User = Object.assign({}, user);
    },

    validate() {
      if (this.$refs.form.validate()) {
        // this.createUser();
        this.createManyUsers();
      } else {
        this.$refs.form.validate();
      }
    },
    async updateUserById() {
      this.isLoading = true;
      const id = this.User.id;
      delete this.User.id;
      delete this.User.__typename;
      delete this.User.employee;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_BY_ID,
          variables: {
            id,
            updateUserInput: {
              ...this.User,
            },
          },
          update: (store, { data: { updateUserById } }) => {
            const data = store.readQuery({ query: GET_USERS });
            const index = data.users.findIndex((value) => value.id === id);
            data.users[index] = updateUserById;
            store.writeQuery({ query: GET_USERS, data });
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.fetch();
      }
    },
    async deleteUserById() {
      this.isLoading = true;
      const id = this.User.id;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_USER,
          variables: {
            id,
          },
          update: (store, { data: { deleteUserById } }) => {
            if (deleteUserById) {
              const data = store.readQuery({ query: GET_USERS });
              const index = data.users.findIndex((value) => value.id === id);
              data.users.splice(index, 1);
              store.writeQuery({ query: GET_USERS, data });
            }
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.fetch();
      }
    },
    async fetch() {
      await this.$apollo.queries.users.refetch();
    },
    closeSnackbar() {
      this.snackbar = false;
    },
    async createUser() {
      this.isLoading = true;
      delete this.User.id;
      delete this.User.__typename;
      delete this.User.employee;
      const index = this.employees.findIndex(
        (value) => value.id === this.User.employeeId
      );
      this.User.firstName = this.employees[index].firstName;
      this.User.lastName = this.employees[index].lastName;
      this.User.email = this.employees[index].email;
      this.User.phoneNumber = this.employees[index].phoneNumber;
      this.User.moderator = this.employees[index].moderator.toString();
      try {
        await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: {
            createUserInput: {
              ...this.User,
              lang: this.$i18n.locale,
            },
          },
          update: (store, { data: { createUser } }) => {
            const data = store.readQuery({ query: GET_USERS });
            data.users.push(createUser);
            store.writeQuery({ query: GET_USERS, data });
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error.message);
        this.timeout = 1000;
        this.snackbar = true;
        this.snackbar_test = error.message;
        setTimeout(() => {
          this.snackbar = false;
        }, this.timeout);
      } finally {
        this.isLoading = false;
        this.fetch();
      }
    },

    async createManyUsers() {
      this.isLoading = true;
      const users = this.employeesIds.map((employeeId) => {
        const employeeIndex = this.employees.findIndex(
          (employee) => employee.id === employeeId
        );
        const code = Math.floor(Math.random() * 90000000) + 10000000;

        return {
          firstName: this.employees[employeeIndex].firstName,
          lastName: this.employees[employeeIndex].lastName,
          email: this.employees[employeeIndex].email,
          phoneNumber: this.employees[employeeIndex].phoneNumber,
          password: code + "",
          type: "EMPLOYEE",
          employeeId,
          roleId: this.User.roleId,
          moderator: this.employees[employeeIndex].moderator.toString(),
          lang: this.$i18n.locale,
        };
      });
      try {
        await this.$apollo.mutate({
          mutation: CREATE_MANY_USERS,
          variables: {
            createManyUsersInput: {
              users,
            },
          },
          update: (store, { data: { createUser } }) => {
            const data = store.readQuery({ query: GET_USERS });
            data.users = [...data.users, ...createUser];
            store.writeQuery({ query: GET_USERS, data });
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error.message);
        this.timeout = 1000;
        this.snackbar = true;
        this.snackbar_test = error.message;
        setTimeout(() => {
          this.snackbar = false;
        }, this.timeout);
      } finally {
        this.isLoading = false;
        this.fetch();
      }
    },
  },
};
</script>

<style lang="css" scoped>
input[type="radio"] {
  width: 0px;
  height: 0px;
}
input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 5px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(137, 134, 134);
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 5px;
  left: -1px;
  position: relative;
  background-color: #80b3af;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
</style>